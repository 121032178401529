import React, { useEffect, useState } from "react";
import fetchCryptoPrices from "../../api/pricefeed.js";
import "../../components/pricefeed/pricefeed.css";
import BtcIcon from "../../assets/icons/btc.svg";
import EthIcon from "../../assets/icons/eth.svg";
import SolIcon from "../../assets/icons/sol.svg";
import CadIcon from "../../assets/icons/cad.svg";
import DogeIcon from "../../assets/icons/dogecoin.svg";
import UsdIcon from "../../assets/icons/usd.svg";
import UsdcIcon from "../../assets/icons/usdc.svg";
import UsdtIcon from "../../assets/icons/usdt.svg";

const PriceFeed = () => {
  const [cryptoData, setCryptoData] = useState<{
    [key: string]: CryptoPriceInfo;
  }>({});
  const [animate, setAnimate] = useState(false);

  interface CryptoPriceInfo {
    EquivalentCurrency: string;
    Currency: string;
    Symbol: string;
    Asset: string;
    Rate?: string;
    Status: string;
  }

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchCryptoPrices();
      if (data && data.data) {
        const dataBySymbol = data.data.reduce(
          (acc: { [key: string]: CryptoPriceInfo }, curr: CryptoPriceInfo) => {
            acc[curr.Symbol] = curr;
            return acc;
          },
          {}
        );

        setAnimate(false);
        requestAnimationFrame(() => setAnimate(true)); // Ensure animation re-triggers

        setCryptoData(dataBySymbol);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 15000);
    return () => clearInterval(intervalId);
  }, []);

  // Format rate to 2 decimal places
  const formatRate = (rate?: string) =>
    rate ? `$ ${parseFloat(rate).toFixed(2)} ` : "Unavailable";

  // Specific cryptocurrency info with formatted rate
  const BTCInfo = formatRate(cryptoData["BTC"]?.Rate);
  const ETHInfo = formatRate(cryptoData["ETH"]?.Rate);
  const XRPInfo = formatRate(cryptoData["XRP"]?.Rate);
  const BNBInfo = formatRate(cryptoData["BNB"]?.Rate);
  const LTCInfo = formatRate(cryptoData["LTC"]?.Rate);
  const USDCInfo = formatRate(cryptoData["USDC"]?.Rate);

  const DOGEInfo = formatRate(cryptoData["DOGE"]?.Rate);

  return (
    <div className="price-feed-container">
      <ul>
        <li className={animate ? "fade-in" : ""}>
          <img src={BtcIcon} alt="btc" width={18}></img>
          BTC: {BTCInfo}
        </li>
        <li className={animate ? "fade-in" : ""}>
          <img src={EthIcon} alt="btc" width={18}></img>ETH: {ETHInfo}
        </li>
        <li className={animate ? "fade-in" : ""}>XRP: {XRPInfo}</li>
        <li className={animate ? "fade-in" : ""}>
          <img src={BtcIcon} alt="btc" width={18}></img>BNB: {BNBInfo}
        </li>
        <li className={animate ? "fade-in" : ""}>LTC: {LTCInfo}</li>
        <li className={animate ? "fade-in" : ""}>
          <img src={UsdcIcon} alt="btc" width={18}></img>USDC: {USDCInfo}
        </li>
        <li className={animate ? "fade-in" : ""}>DOGE: {DOGEInfo}</li>
      </ul>
    </div>
  );
};

export default PriceFeed;

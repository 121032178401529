import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import the DatePicker default styles
import "../../components/reports/reports.css"; // Import your custom stylesheet
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const Reports = ({ closePopup }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [reportType, setReportType] = useState("");
  const [fileType, setFileType] = useState("");
  const [isUtc, setIsUtc] = useState(false);

  const reportTypes = ["Trades", "Holdings", "Settlements"];
  const fileTypes = ["CSV", "PDF", "XLS"];

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log({ startDate, endDate, reportType, fileType, isUtc });
    // Optionally reset the form fields after submission
    setEndDate(new Date());
    setStartDate(new Date());
    setReportType("");
    setFileType("");
    setIsUtc(false);
  };

  return (
    <div className="reports-container">
      <IconButton
        className="close-button"
        onClick={closePopup}
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>
      <form onSubmit={handleSubmit}>
        <div className="form-field">
          <DatePicker
            style={{ fontFamily: "'Your Font Family', sans-serif" }}
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            dateFormat="yyyy/MM/dd"
            className="input-style"
          />
        </div>
        <div className="form-field">
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            dateFormat="yyyy/MM/dd"
            className="input-style"
          />
        </div>
        <div className="form-field">
          <select
            value={reportType}
            onChange={(e) => setReportType(e.target.value)}
            className="input-style"
          >
            <option value="" disabled>
              Report type
            </option>
            {reportTypes.map((type, index) => (
              <option key={index} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>
        <div className="form-field">
          <select
            value={fileType}
            onChange={(e) => setFileType(e.target.value)}
            className="input-style"
          >
            <option value="" disabled>
              File type
            </option>
            {fileTypes.map((type, index) => (
              <option key={index} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>
        <div className="form-field">
          <label className="checkbox-label">
            <input
              type="checkbox"
              checked={isUtc}
              onChange={(e) => setIsUtc(e.target.checked)}
              className="checkbox-style"
            />
            UTC
          </label>
        </div>
        <div className="form-field">
          <button type="submit" className="button-style">
            Export
          </button>
        </div>
      </form>
    </div>
  );
};

export default Reports;

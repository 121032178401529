import React from "react";
import { ChartOptions } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, Tooltip, Legend as ChartJSLegend } from "chart.js";
import "chart.js/auto";
import "../chart/chart.css";

const data = {
  labels: ["BTC", "ETH", "SOL", "CAD", "USD"],
  datasets: [
    {
      label: "# of Votes",
      data: [12.8, 8.22, 22.03, 15.03, 3.03],
      backgroundColor: [
        "#F7C411",
        "rgb(0, 153, 255)",
        "rgb(255, 0, 55)",
        "rgb(0, 255, 255)",
        "rgb(85, 0, 255)",
      ],
      borderColor: [
        "#F7C411",
        "#0098fe",
        "#ff0037",
        "rgba(75, 192, 192, 1)",
        "#9d00ff",
      ],
      borderWidth: 1,
    },
  ],
};

const options: ChartOptions<"doughnut"> = {
  responsive: true,
  maintainAspectRatio: false,
  cutout: 60,
  plugins: {
    legend: {
      display: false,
    },
  },
};

ChartJS.register(Tooltip, ChartJSLegend);

const PieChart = () => (
  <div className="chart-layout">
    <div className="chart-container">
      <Doughnut data={data} options={options} />
    </div>
    <div className="legend-container">
      {data.datasets[0].data.map((value, index) => (
        <div key={index} className="legend-item">
          <span
            className="legend-color"
            style={{ backgroundColor: data.datasets[0].backgroundColor[index] }}
          ></span>
          <span className="legend-label">{`${value.toFixed(2)}% ${
            data.labels[index]
          }`}</span>
        </div>
      ))}
    </div>
  </div>
);

export default PieChart;

import React, { useState } from "react";
import BalanceHeader from "../../components/balances/balanceHeader.tsx";
import Sidenav from "../../components/sidenavbar/sideNavbar.tsx";
import Deposits from "../../components/tables/deposits.tsx";
import Withdrawals from "../../components/tables/withdrawals.tsx";
import Balances from "../../components/balances/balances.tsx";
import SettementForm from "../../components/settlements-form/form.tsx";
import PriceFeed from "../../components/pricefeed/pricefeed.tsx";
import "../settlements/settlements.css";

const Settlements = () => {
  const [activeSection, setActiveSection] = useState("deposits");

  const navItems = [
    { id: "deposits", label: "Deposits" },
    { id: "withdrawals", label: "Withdrawals" },
    { id: "transfers", label: "Transfers" },
  ];

  return (
    <>
      <PriceFeed />
      <div className="bal-head-container">
        <BalanceHeader />
      </div>

      <div className="form-contianer">
        <SettementForm />
      </div>

      <div className="table-container-settlements">
        <Sidenav onSectionChange={setActiveSection} navItems={navItems} />
        {activeSection === "deposits" && <Deposits />}
        {activeSection === "withdrawals" && <Withdrawals />}
        {/* {activeSection === 'transfers' && <Transfers />} */} */
      </div>
    </>
  );
};

export default Settlements;

import React from "react";
import MainTable from "../../components/tables/mainTable.tsx";
import "../../components/tables/mainTable.tsx";
import WithdrawalData from "../../data/WithdrawlData.json";

const Withdrawals = () => {
  const columns = [
    { id: "Date", label: "Date", dataKey: "Date", hideOnSmallScreen: false },
    {
      id: "Currency",
      label: "Currency",
      dataKey: "Currency",
      hideOnSmallScreen: false,
    },
    {
      id: "Amount",
      label: "Amount",
      dataKey: "Amount",
      hideOnSmallScreen: false,
    },
    { id: "Type", label: "Deposit", dataKey: "Type", hideOnSmallScreen: false },
    {
      id: "Status",
      label: "Status",
      dataKey: "Status",
      hideOnSmallScreen: false,
    },
    {
      id: "TransactionID",
      label: "Transaction ID",
      dataKey: "TransactionID",
      hideOnSmallScreen: true,
    },
    {
      id: "TransactionHash",
      label: "Transaction Hash",
      dataKey: "TransactionHash",
      hideOnSmallScreen: true,
    },
  ];

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <MainTable data={WithdrawalData} columns={columns} />
    </div>
  );
};

export default Withdrawals;

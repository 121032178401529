import React, { useState } from "react";
import {
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Box,
  Typography,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import "../../components/settlements-form/form.css";

const FormComponent = () => {
  const [action, setAction] = useState("");
  const [currency, setCurrency] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleActionChange = (event) => {
    setAction(event.target.value);
  };

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  const handleCopyAddress = () => {
    navigator.clipboard
      .writeText("bc1qa03edfyvwpwcpa02xtw472jfjnn8pfl8f7w3ac")
      .then(() => {
        setOpenSnackbar(true); // Show confirmation snackbar
        setTimeout(() => setOpenSnackbar(false), 3000); // Close snackbar after 3 seconds
      })
      .catch((err) => console.error("Failed to copy address:", err));
  };

  const generateMessage = () => {
    if (!action) {
      return (
        <div className="settle-message">
          <p>Please select an action for instructions</p>
        </div>
      );
    } else if (action === "withdrawal") {
      return (
        <div className="settle-message">
          <span style={{ color: "gold" }}>
            Please contact{" "}
            <a href="mailto:sdm@sdm.co" style={{ color: "white" }}>
              sdm@sdm.co
            </a>{" "}
            for withdrawal requests.
          </span>
        </div>
      );
    } else if (action === "deposit" && currency) {
      return (
        <>
          <div className="settle-message">
            <span
              style={{ color: "grey" }}
            >{`Please only send ${currency} to this deposit address:`}</span>
            <p>
              Sending any other coin or token to this address may result in the
              loss of your deposit.
            </p>
          </div>
          <div className="settle-message">
            <span style={{ color: "grey" }}>Address:</span>
            <p>
              bc1qa03edfyvwpwcpa02xtw472jfjnn8pfl8f7w3ac
              <Button onClick={handleCopyAddress} size="small" sx={{ ml: 1 }}>
                Copy
              </Button>
            </p>
          </div>
        </>
      );
    } else if (action === "deposit") {
      return (
        <div className="settle-message">
          <p>Please select a currency for deposit instructions</p>
        </div>
      );
    }
  };

  return (
    <Box
      sx={{
        minWidth: 120,
        display: "flex",
        flexDirection: "row",
        height: "auto",
        gap: 15,
      }}
    >
      <Box
        sx={{ display: "flex", flexDirection: "column", gap: 2, width: "50%" }}
      >
        <FormControl fullWidth>
          <InputLabel id="action-label" sx={{ color: "white" }}>
            Withdrawal / Deposit
          </InputLabel>
          <Select
            labelId="action-label"
            id="action-select"
            value={action}
            onChange={handleActionChange}
            sx={{
              color: "white",
              "& .MuiSvgIcon-root": {
                color: "white",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
              "& .MuiSelect-select": {
                "&:focus": {
                  backgroundColor: "transparent",
                },
              },
            }}
            MenuProps={{
              sx: {
                "& .MuiPaper-root": {
                  backgroundColor: "black",
                  color: "white",
                },
                "& .MuiMenuItem-root": {
                  "&:hover": {
                    backgroundColor: "#505050",
                  },
                },
              },
            }}
          >
            <MenuItem value="deposit">Deposit</MenuItem>
            <MenuItem value="withdrawal">Withdrawal</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="currency-label" sx={{ color: "white" }}>
            Currency
          </InputLabel>
          <Select
            labelId="currency-label"
            id="currency-select"
            value={currency}
            onChange={handleCurrencyChange}
            sx={{
              color: "white",
              "& .MuiSvgIcon-root": {
                color: "white",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
              "& .MuiSelect-select": {
                "&:focus": {
                  backgroundColor: "transparent",
                },
              },
            }}
            MenuProps={{
              sx: {
                "& .MuiPaper-root": {
                  backgroundColor: "black",
                  color: "white",
                },
                "& .MuiMenuItem-root": {
                  "&:hover": {
                    backgroundColor: "#505050",
                  },
                },
              },
            }}
          >
            <MenuItem value="BTC">BTC</MenuItem>
            <MenuItem value="ETH">ETH</MenuItem>
            <MenuItem value="CAD">CAD</MenuItem>
            <MenuItem value="USD">USD</MenuItem>
            <MenuItem value="USDC">USDC</MenuItem>
            <MenuItem value="LTC">LTC</MenuItem>
            <MenuItem value="SOL">SOL</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ fontSize: "15px", lineHeight: "2em" }}>
        {generateMessage()}
      </Box>

      {/* Snackbar for copy confirmation */}
      <Snackbar open={openSnackbar} autoHideDuration={3000}>
        <Alert severity="success" sx={{ width: "100%" }}>
          Address copied to clipboard!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default FormComponent;

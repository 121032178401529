import React, { useState } from "react";
import "../sidenavbar/sideNavbar.css";

const SideNavbar = ({ navItems, onSectionChange }) => {
  const [activeSection, setActiveSection] = useState(navItems[0].id);

  const handleSectionChange = (id) => {
    setActiveSection(id);
    onSectionChange(id);
  };

  return (
    <div className="side-nav">
      <div className="side-nav-container"></div>
      {navItems.map((item) => (
        <span
          key={item.id}
          onClick={() => handleSectionChange(item.id)}
          style={
            activeSection === item.id
              ? {
                  color: "gold",
                  borderRight: "solid gold 1px",
                  fontWeight: "500",
                }
              : undefined
          }
        >
          {item.label}
        </span>
      ))}
    </div>
  );
};

export default SideNavbar;

import React, { useState } from "react";
import { NavLink } from "react-router-dom"; // Change this line
import "../navbar/navbar.css";
import Logo from "../../assets/Logo.svg";
import Hamburger from "hamburger-react";
import Pricefeed from "../pricefeed/pricefeed.tsx";

const Navbar = () => {
  const [isOpen, setOpen] = useState(false);

  const handleNavLinkClick = () => {
    setOpen(false);
  };

  return (
    <div className="nav-container">
      <div className="navbar">
        <img src={Logo} alt="Logo" width={200} />
        <div className="hamburger">
          <Hamburger
            toggled={isOpen}
            toggle={setOpen}
            color="#fff"
            size={20}
            rounded
          />
        </div>

        {isOpen && (
          <div className="mobile-nav">
            <div className="mobile-nav-header">
              <img src={Logo} alt="Logo" width={200} />
            </div>

            <NavLink
              to="/Portfolio"
              className="nav-link"
              onClick={handleNavLinkClick}
            >
              Portfolio
            </NavLink>
            <NavLink
              to="/settlements"
              className="nav-link"
              onClick={handleNavLinkClick}
            >
              Settlements
            </NavLink>
            <NavLink
              to="/trading"
              className="nav-link"
              onClick={handleNavLinkClick}
            >
              Trading
            </NavLink>
            <NavLink
              to="/account"
              className="nav-link"
              onClick={handleNavLinkClick}
            >
              Account
            </NavLink>
          </div>
        )}
      </div>

      <div className="nav-menu">
        <div className="nav-link-item">
          <NavLink
            to="/Portfolio"
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
          >
            Portfolio
          </NavLink>
        </div>
        <div className="nav-link-item">
          <NavLink
            to="/settlements"
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
          >
            Settlements
          </NavLink>
        </div>
        <div className="nav-link-item">
          <NavLink
            to="/trading"
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
          >
            Trading
          </NavLink>
        </div>
        <div className="nav-link-item">
          <NavLink
            to="/account"
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
          >
            Account
          </NavLink>
        </div>
      </div>
      {/* <Pricefeed/> */}
    </div>
  );
};

export default Navbar;

import React, { useState } from "react";
import "../balances/balances.css"; // Ensure the path is correct
import Eye from "../../assets/eye.svg";

const Balances = () => {
  const [isVisible, setIsVisible] = useState(true); // State to toggle visibility
  const data = {
    netValue: "133,492.52",
    avaliTrade: "33,000",
    credit: "5.00%",
    pnl: "$2,304",
  };

  // Function to toggle the visibility state
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <>
      <div className="balance-container">
        <div className="balance-display">
          <h1>Net Asset Value (USD)</h1>
          <h2>${data.netValue}</h2>
        </div>
      </div>
    </>
  );
};

export default Balances;

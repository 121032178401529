import React from "react";
import MainTable from "../tables/mainTable.tsx";
import FundingData from "../../data/Funding.json";

const Funding = () => {
  const columns = [
    { id: "Date", label: "Date", dataKey: "Date", hideOnSmallScreen: false },
    {
      id: "Currency",
      label: "Currency",
      dataKey: "Currency",
      hideOnSmallScreen: false,
    },
    {
      id: "Amount",
      label: "Amount",
      dataKey: "Amount",
      hideOnSmallScreen: false,
    },
    {
      id: "Type",
      label: "Deposit/Withdrawal",
      dataKey: "Type",
      hideOnSmallScreen: false,
    },
    {
      id: "Status",
      label: "Status",
      dataKey: "Status",
      hideOnSmallScreen: false,
    },
    {
      id: "TransactionID",
      label: "Transaction ID",
      dataKey: "TransactionID",
      hideOnSmallScreen: true,
    },
    {
      id: "TransactionHash",
      label: "Transaction Hash",
      dataKey: "TransactionHash",
      hideOnSmallScreen: true,
    },
  ];

  return (
    <div>
      <MainTable data={FundingData} columns={columns} />
    </div>
  );
};

export default Funding;

import React from "react";
import MainTable from "../tables/mainTable.tsx";
import HistoryData from "../../data/HistoryData.json";

const tradehistory = () => {
  const columns = [
    { id: "date", label: "Date", dataKey: "date" },
    { id: "pair", label: "Pair", dataKey: "pair" },
    { id: "buySell", label: "Buy/Sell", dataKey: "buySell" },
    { id: "amount", label: "Amount", dataKey: "amount" },
    { id: "dealtRate", label: "Dealt Rate", dataKey: "dealtRate" },
    { id: "spotPrice", label: "Spot Price", dataKey: "spotPrice" },
    { id: "orderId", label: "Order ID", dataKey: "orderId" },
  ];

  return <MainTable columns={columns} data={HistoryData} />;
};

export default tradehistory;

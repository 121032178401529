import React, { useState } from "react";
import PieChart from "../chart/chart.tsx";
import "../balances/balances.css";
import Reports from "../reports/reports.tsx";

const Balances = () => {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const data = {
    trade: "23,794",
    pnl: "7,890",
    credit: "5.00",
  };

  return (
    <div className="bal-wrapper">
      <div className="bal-display" style={{ position: "relative" }}>
        <div className="box">
          <h2>Available to Trade (USD)</h2>
          <span>${data.trade}</span>
        </div>
        <div className="box">
          <h2>Profit & Loss (USD)</h2>
          <span>${data.pnl}</span>
        </div>
        <div className="box">
          <h2>Credit Utilization</h2>
          <span>${data.credit}</span>
        </div>
        <div className="box download-report" onClick={togglePopup}>
          <h2>Download Reports</h2>
        </div>
        {showPopup && (
          <div className="popup">
            <Reports closePopup={togglePopup} />
          </div>
        )}
      </div>
      <div className="bal-chart">
        <PieChart />
      </div>
    </div>
  );
};

export default Balances;

// AccountDetails.js
import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import "../account-details/account-details.css";

// Define modal styles outside the component
const modalStyle = getModalStyle();

// Function to return modal styles based on screen width
function getModalStyle() {
  const isMobile = window.innerWidth <= 480;
  return {
    position: "absolute",
    top: isMobile ? "10%" : "50%",
    left: isMobile ? "5%" : "50%",
    transform: isMobile ? "none" : "translate(-50%, -50%)",
    width: isMobile ? "90%" : "auto",
    maxWidth: "90vw",
    bgcolor: "#121212",
    color: "#fff",
    border: "1px solid #333",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    overflowY: "auto", // Add scroll for overflowing content
    maxHeight: "80vh", // Limit height to ensure it fits within the viewport
  };
}
const AccountDetails = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  // Function to close the modal
  const handleClose = () => setOpen(false);

  // Function to handle form field changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    // Here you would handle submitting these details to update them
    console.log(formValues);
    handleClose();
  };
  const [formValues, setFormValues] = useState({
    name: "",
    company: "",
    email: "",
    address: "",
  });

  return (
    <div className="account-details-container">
      <section className="account-section">
        <h2>Main account holder</h2>
        <div className="account-info">
          <p>
            <strong>Name:</strong> Secure Markets
          </p>
          <p>
            <strong>Company:</strong> Secure Digital Markets
          </p>
          <p>
            <strong>Email:</strong> sdm@securedigitalmarkets.com
          </p>
          <p>
            <strong>Address:</strong> 626 King St W, Toronto, ON M5V 1M5, Canada
          </p>
        </div>
        <Button
          className="edit-button"
          variant="contained"
          onClick={handleOpen}
        >
          Edit
        </Button>
      </section>

      <section className="account-section">
        <h2>Secondary account holder</h2>
        <div className="account-info">
          <p>
            <strong>Name:</strong> Mac Shafirir
          </p>
          <p>
            <strong>Company:</strong> SDMF inc.
          </p>
          <p>
            <strong>Email:</strong> mac_shafirir@securedigitalmarkets.com
          </p>
          <p>
            <strong>Address:</strong> 626 King St W, Toronto, ON M5V 1M5, Canada
          </p>
        </div>
      </section>

      <Button className="edit-button" variant="contained" onClick={handleOpen}>
        Edit
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={modalStyle}
          component="form"
          onSubmit={handleSubmit}
          noValidate
        >
          <Typography id="modal-title" variant="h6" component="h2">
            Edit Account Details
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            autoComplete="name"
            autoFocus
            value={formValues.name}
            onChange={handleChange}
            InputProps={{
              style: {
                color: "#fff",
              },
            }}
            InputLabelProps={{
              style: {
                color: "#aaa",
              },
            }}
            sx={{
              "& .MuiFilledInput-root": {
                backgroundColor: "rgba(255, 255, 255, 0.09)",
              },
              "& .MuiFilledInput-root:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.13)",
              },
              "& .MuiFilledInput-root.Mui-focused": {
                backgroundColor: "rgba(255, 255, 255, 0.09)",
              },
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="company"
            label="Company"
            name="company"
            autoComplete="company"
            value={formValues.company}
            onChange={handleChange}
            InputProps={{
              style: {
                color: "#fff",
              },
            }}
            InputLabelProps={{
              style: {
                color: "#aaa",
              },
            }}
            sx={{
              "& .MuiFilledInput-root": {
                backgroundColor: "rgba(255, 255, 255, 0.09)",
              },
              "& .MuiFilledInput-root:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.13)",
              },
              "& .MuiFilledInput-root.Mui-focused": {
                backgroundColor: "rgba(255, 255, 255, 0.09)",
              },
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={formValues.email}
            onChange={handleChange}
            InputProps={{
              style: {
                color: "#fff",
              },
            }}
            InputLabelProps={{
              style: {
                color: "#aaa",
              },
            }}
            sx={{
              "& .MuiFilledInput-root": {
                backgroundColor: "rgba(255, 255, 255, 0.09)",
              },
              "& .MuiFilledInput-root:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.13)",
              },
              "& .MuiFilledInput-root.Mui-focused": {
                backgroundColor: "rgba(255, 255, 255, 0.09)",
              },
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="address"
            label="Address"
            name="address"
            autoComplete="address"
            value={formValues.address}
            onChange={handleChange}
            InputProps={{
              style: {
                color: "#fff",
              },
            }}
            InputLabelProps={{
              style: {
                color: "#aaa",
              },
            }}
            sx={{
              "& .MuiFilledInput-root": {
                backgroundColor: "rgba(255, 255, 255, 0.09)",
              },
              "& .MuiFilledInput-root:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.13)",
              },
              "& .MuiFilledInput-root.Mui-focused": {
                backgroundColor: "rgba(255, 255, 255, 0.09)",
              },
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Save
          </Button>
          <Button onClick={handleClose} fullWidth sx={{ mt: 1 }}>
            Cancel
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default AccountDetails;

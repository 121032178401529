import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
  useMediaQuery,
  TablePagination,
  Paper,
} from "@mui/material";
import "../tables/maintable.css";
import BtcIcon from "../../assets/icons/btc.svg";
import EthIcon from "../../assets/icons/eth.svg";
import SolIcon from "../../assets/icons/sol.svg";
import CadIcon from "../../assets/icons/cad.svg";
import DogeIcon from "../../assets/icons/dogecoin.svg";
import UsdIcon from "../../assets/icons/usd.svg";
import UsdcIcon from "../../assets/icons/usdc.svg";
import UsdtIcon from "../../assets/icons/usdt.svg";

const MainTable = ({ data, columns }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(isSmallScreen ? 5 : 8);

  React.useEffect(() => {
    setRowsPerPage(isSmallScreen ? 5 : 8);
  }, [isSmallScreen]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Changes the text color based on the column type and data that is displayed.
  const getCellStyle = (column, row) => {
    if (column.dataKey === "buySell") {
      return {
        color:
          row.buySell === "BUY"
            ? "green"
            : row.buySell === "SELL"
            ? "red"
            : "inherit",
      };
    } else if (column.dataKey === "Status") {
      return {
        color:
          row.Status === "Approved"
            ? "green"
            : row.Status === "Declined"
            ? "red"
            : row.Status === "Requested"
            ? "yellow"
            : "inherit",
      };
    }
    return {}; // Default style if no conditions are met
  };

  const assetIcons = {
    BTC: BtcIcon,
    ETH: EthIcon,
    SOL: SolIcon,
    CAD: CadIcon,
    DOGE: DogeIcon,
    USD: UsdIcon,
    USDC: UsdcIcon,
    UsdtIcon: UsdtIcon,
  };

  return (
    <div className="main-table-contianer">
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: "100vh",
          overflow: "hidden", // Change here to "hidden" to prevent the TableContainer itself from scrolling
          width: "100%", // Ensure the container takes full width
        }}
      >
        <div style={{ overflowX: "auto", width: "100%" }}>
          <Table
            sx={{
              minWidth: 750, // Set a minWidth to ensure the table extends beyond small screens
              backgroundColor: "black",
              color: "white",
              ".MuiTableRow-root": { height: "40px" },
              ".MuiTableCell-root": {
                color: "white",
                padding: isSmallScreen ? "3px" : "6px",
                fontSize: isSmallScreen ? "0.75rem" : "1rem",
                border: "none",
                textAlign: "center",
              },
              ".MuiTableHead-root .MuiTableCell-root": {
                backgroundColor: "rgb(24, 24, 24)",
                top: 0,
                color: "white",
                zIndex: (theme) => theme.zIndex.appBar + 1,
              },
              ".MuiTableBody-root .MuiTableRow-root:nth-of-type(odd)": {
                backgroundColor: "#000000",
              },
              ".MuiTableBody-root .MuiTableRow-root:nth-of-type(even)": {
                backgroundColor: "#1a1a1a",
              },
            }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                {columns.map((column) =>
                  !column.hideOnSmallScreen || !isSmallScreen ? (
                    <TableCell key={column.id}>{column.label}</TableCell>
                  ) : null
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row.id}>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        style={getCellStyle(column, row)}
                      >
                        {column.dataKey === "asset" &&
                        assetIcons[row[column.dataKey]] ? (
                          <div className="assetCell">
                            <img
                              src={assetIcons[row[column.dataKey]]}
                              alt={row[column.dataKey]}
                              style={{ width: "18px", height: "auto" }}
                            />
                            <span className="asset-name">
                              {row[column.dataKey]}
                            </span>
                          </div>
                        ) : (
                          row[column.dataKey]
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            ".MuiToolbar-root": {
              justifyContent: "flex-start",
              backgroundColor: "black",
              color: "white",
              minHeight: "40px",
              overflow: "hidden",
              // flexDirection: 'row-reverse',
            },
            ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
              {
                color: "white",
              },
            ".MuiTablePagination-select, .MuiTablePagination-selectIcon, .MuiTablePagination-actions":
              {
                color: "white",
              },
            ".MuiSvgIcon-root": {
              fontSize: "1rem",
              color: "white",
            },
          }}
        />
      </TableContainer>
    </div>
  );
};

export default MainTable;

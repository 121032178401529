import axios from 'axios';

const fetchCryptoPrices = async () => {
  try {
    // Corrected URL (removed the 'https://')
    const response = await axios.get('http://localhost:3005/api/data');
    
    // Now, log the response correctly
    console.log(response.data);

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
};

export default fetchCryptoPrices;
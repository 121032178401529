import React, { useState } from "react";
import Sidenav from "../../components/sidenavbar/sideNavbar.tsx";
import PriceFeed from "../../components/pricefeed/pricefeed.tsx";
import AccountDetails from "../../components/account-details/accountDetails.tsx";
import AccountApi from "../../components/account-api/accountApi.tsx";
import "../account/account.css";

const Account = () => {
  const [activeSection, setActiveSection] = useState("account-details");

  const navItems = [
    { id: "account-details", label: "Account Details" },
    { id: "api", label: "API Key" },
    { id: "security", label: "Security" },
  ];

  console.log(activeSection);

  return (
    <>
      <PriceFeed />
      <div className="account-contianer">
        <div className="nav-container">
          <Sidenav onSectionChange={setActiveSection} navItems={navItems} />
        </div>

        {activeSection === "account-details" && <AccountDetails />}
        {activeSection === "api" && <AccountApi />}
      </div>
    </>
  );
};

export default Account;

// In Index.js
import React from "react";
import Holdings from "../../components/tables/holdings.tsx";
import TradeHistory from "../tables/tradehistory.tsx";
import Funding from "../tables/funding.tsx";
import Derivatives from "../tables/derivatives.tsx";
import "../tables/index.css";

const Index = ({ activeSection }) => {
  return (
    <div className="index-container">
      {activeSection === "holdings" && <Holdings />}
      {activeSection === "tradeHistory" && <TradeHistory />}
      {activeSection === "funding" && <Funding />}
      {activeSection === "derivatives" && <Derivatives />}
    </div>
  );
};

export default Index;

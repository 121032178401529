import React, { useState } from "react";
import Button from "@mui/material/Button";
import "../account-api/accountapi.css";

const AccountApi = () => {
  const [apiKey, setApiKey] = useState(""); // Initialize state with an empty string
  const user = {
    email: "sdm@securedigitalmarkets.com",
  };

  // Function to generate API key
  const generateApiKey = () => {
    // You can generate the API key logic here, for now let's simulate a random API key
    const randomApiKey = Math.random().toString(36).substring(7);
    setApiKey(randomApiKey);
  };

  return (
    <div className="account-api-container">
      <div className="account-info">
        <span>Account Information</span>
        <h2>
          <strong>Email: </strong>
          {user.email}
        </h2>
        <p>
          Please contact your SDM Account Manager to configure your trading
          access
        </p>
        <p>support@securedigitalmarkets.com</p>

        {/* Button to generate API key */}
        <Button
          variant="contained"
          style={{ backgroundColor: "#FFC32C", color: "#000" }}
          onClick={generateApiKey}
        >
          Generate API Key
        </Button>

        {/* Display the generated API key */}
        {apiKey && (
          <div>
            <p>
              <strong>API Key:</strong> {apiKey}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountApi;

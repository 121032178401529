import React from "react";
import MainTable from "../tables/mainTable.tsx";
import HoldingsData from "../../data/Holdings.json";

const Holdings = () => {
  const columns = [
    { id: "asset", label: "Asset", dataKey: "asset", hideOnSmallScreen: false },
    { id: "share", label: "Share", dataKey: "share", hideOnSmallScreen: true },
    {
      id: "priceChange",
      label: "Price Change",
      dataKey: "priceChange",
      hideOnSmallScreen: true,
    },
    {
      id: "netBalance",
      label: "Net Balance (USD)",
      dataKey: "netBalance",
      hideOnSmallScreen: false,
    },
    {
      id: "netUSD",
      label: "Net (USD)",
      dataKey: "netUSD",
      hideOnSmallScreen: true,
    },
    {
      id: "availableWithdrawal",
      label: "Available Withdrawal",
      dataKey: "availableWithdrawal",
      hideOnSmallScreen: false,
    },
  ];

  return (
    <div>
      <MainTable data={HoldingsData} columns={columns} />
    </div>
  );
};

export default Holdings;

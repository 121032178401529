import React, { useState } from "react";
import BalanceHeader from "../../components/balances/balanceHeader.tsx";
import Balances from "../../components/balances/balances.tsx";
import Sidenav from "../../components/sidenavbar/sideNavbar.tsx";
import Index from "../../components/tables/index.tsx";
import "../../pages/portfolio/portfolio.css";
import PriceFeed from "../../components/pricefeed/pricefeed.tsx";

const navItems = [
  { id: "holdings", label: "Holdings" },
  { id: "tradeHistory", label: "Trade History" },
  { id: "funding", label: "Funding" },
  { id: "derivatives", label: "Derivatives" },
];

const Portfolio = () => {
  const [activeSection, setActiveSection] = useState("holdings");

  return (
    <div>
      <PriceFeed />
      <div className="bal-head-container">
        <BalanceHeader />
      </div>

      <div className="bal-head-container">
        <Balances />
      </div>

      <div className="table-container">
        <Sidenav onSectionChange={setActiveSection} navItems={navItems} />
        <Index activeSection={activeSection} />
      </div>
    </div>
  );
};

export default Portfolio;

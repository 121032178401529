import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import "./fonts.css";
import Navbar from "./components/navbar/navbar.tsx";
import Portfolio from "./pages/portfolio/Portfolio.tsx";
import Settlements from "./pages/settlements/settlements.tsx";
import Account from "./pages/account/account.tsx";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/Portfolio" element={<Portfolio />} />
        <Route path="/Settlements" element={<Settlements />} />
        <Route path="/account" element={<Account />} />
      </Routes>
    </div>
  );
}

export default App;
